import React from 'react'
import PropTypes from 'prop-types'
import sum from 'lodash/sum'
import {
  IssueStatusTypes,
  AssessmentStatusTypes,
  LabelTypes,
} from 'constants/index'
import { useAuthorizations } from 'hooks'
import { issueDueDateFormatterFactory, getIssueStatusLabel } from 'lib/issue'
import { projectStatusFormatterFactory } from 'lib/project'
import { getPageUrl } from 'utils/url'
import Button from 'components-v2/atoms/Button'
import Table from 'components-v2/molecules/Table'
import { Row } from 'components-v2/organisms/Layout'
import PageHeader from 'components-v2/organisms/PageHeader'
import Widget from './Widget'
import StatsWidget from './StatsWidget'
import AssessmentStatusChartWidget from './AssessmentStatusChartWidget'
import AssessmentScoreChartWidget from './AssessmentScoreChartWidget'
import IssueRankingMatrix from './IssueRankingMatrix'
import {
  getAssessmentsInStatusPageUrl,
  getIssuesInStatusPageUrl,
  // getAssessmentsPageUrl,
} from './utils'
import { Wrapper, StyledCol as Col } from './styles'

const dueDateFormatter = issueDueDateFormatterFactory()
const projectStatusFormatter = projectStatusFormatterFactory()

const getRecentProjectsTableColumns = (canLink) => [
  {
    id: 'friendly_name',
    header: 'Project',
    cell: (info) =>
      canLink ? (
        <a href={info.row.original.path} title={info.getValue()}>
          {info.getValue()}
        </a>
      ) : (
        info.getValue()
      ),
    classes: 'text-ellipsis',
    width: '100%',
  },
  {
    id: 'status',
    header: 'Status',
    cell: projectStatusFormatter,
  },
]
const getUpcomingIssuesTableColumns = (onSendIssue, canLink, canManage) => [
  {
    id: 'vendor.name',
    header: LabelTypes.VENDOR,
    cell: (info) => <span title={info.getValue()}>{info.getValue()}</span>,
    classes: 'text-ellipsis',
    width: '50%',
  },
  {
    id: 'summary',
    header: 'Issue',
    cell: (info) =>
      canLink ? (
        <a href={info.row.original.path} title={info.getValue()}>
          {info.getValue()}
        </a>
      ) : (
        info.getValue()
      ),
    classes: 'text-ellipsis',
    width: '50%',
  },
  {
    id: 'due_at',
    header: 'Due',
    cell: dueDateFormatter,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (info) => {
      const status = getIssueStatusLabel(info.getValue(), false)
      if (info.getValue() === IssueStatusTypes.CREATED && canManage) {
        return (
          <div>
            <div>{status}</div>
            <Button
              color="primary"
              size="xsmall"
              onClick={() => onSendIssue(info.row.original.id)}
            >
              Send
            </Button>
          </div>
        )
      }
      return status
    },
  },
]

const DashboardView = ({
  overview,
  assessmentGrades,
  issueMatrix,
  activeAssessmentStatusCounts,
  recentProjects,
  recentAssessmentScores,
  upcomingIssues,
  onSendIssue,
  onAssessmentStatusSelect,
  preferences,
  ...rest
}) => {
  const [
    canListAssessments,
    canLinkAssessment,
    canCreateTemplate,
    canCreateProject,
    canListProjects,
    canLinkProject,
    canListIssues,
    canLinkIssue,
    canManageIssue,
  ] = useAuthorizations(
    ['index', 'Assessment'],
    ['show', 'Assessment'],
    ['create', 'AssessmentTemplate'],
    ['create', 'Project'],
    ['index', 'Project'],
    ['show', 'Project'],
    ['index', 'Issue'],
    ['show', 'Issue'],
    ['manage', 'Issue'],
  )
  const statsDetails1 = React.useMemo(
    () => [
      {
        number:
          sum(
            preferences.assessments_review_statuses?.map(
              (e) => Number(overview.active_assessment_status_counts[e]) || 0,
            ),
          ) || 0,
        title: 'Assessments to Review',
        link: getAssessmentsInStatusPageUrl(
          preferences.assessments_review_statuses,
        ),
        active: true,
        canLink: canListAssessments,
      },
      {
        number: overview.issue_status_counts.responded,
        title: 'Issues to Review',
        link: getIssuesInStatusPageUrl(IssueStatusTypes.RESPONDED),
        active: true,
        canLink: canListIssues,
      },
    ],
    [overview],
  )
  const statsDetails2 = React.useMemo(
    () => [
      {
        number: overview.active_assessment_status_counts.invited,
        title: 'Invited Assessments',
        link: getAssessmentsInStatusPageUrl(AssessmentStatusTypes.INVITED),
        active: true,
        canLink: canListAssessments,
      },
      {
        number: overview.issue_status_counts.opened,
        title: 'Open Issues',
        link: getIssuesInStatusPageUrl(IssueStatusTypes.OPENED),
        active: true,
        canLink: canListIssues,
      },
    ],
    [overview],
  )
  const statsDetails3 = React.useMemo(
    () => [
      {
        number: assessmentGrades.average_assessment_score ? (
          <span>
            {Number(assessmentGrades.average_assessment_score)}
            <sup>%</sup>
          </span>
        ) : undefined,
        title: 'Average score',
        link: getAssessmentsInStatusPageUrl(
          AssessmentStatusTypes.REVIEWED,
          true,
        ),
        canLink: canListAssessments,
      },
      {
        number: overview.issue_status_counts.closed,
        title: 'Closed Issues',
        link: getIssuesInStatusPageUrl(IssueStatusTypes.CLOSED),
        canLink: canListIssues,
      },
    ],
    [overview, assessmentGrades],
  )
  const recentProjectsTableColumns = React.useMemo(
    () => getRecentProjectsTableColumns(canLinkProject),
    [canLinkProject],
  )
  const upcomingIssuesTableColumns = React.useMemo(
    () =>
      getUpcomingIssuesTableColumns(onSendIssue, canLinkIssue, canManageIssue),
    [onSendIssue, canLinkIssue, canManageIssue],
  )
  return (
    <Wrapper {...rest}>
      <PageHeader title="Dashboard">
        <Button href={getPageUrl('clientReports')}>
          <i className="fa fa-pie-chart" />
          Reports
        </Button>
      </PageHeader>
      <Row $equalHeight>
        <Col md={4}>
          <StatsWidget details={statsDetails1} />
        </Col>
        <Col md={4}>
          <StatsWidget details={statsDetails2} />
        </Col>
        <Col md={4}>
          <StatsWidget details={statsDetails3} />
        </Col>
      </Row>
      <Row $equalHeight>
        <Col md={4}>
          <AssessmentStatusChartWidget
            title="Assessment Status"
            canLink={canListAssessments}
            data={activeAssessmentStatusCounts}
            onStatusSelect={onAssessmentStatusSelect}
            defaultContent={
              <span>
                You don&apos;t have any Assessments.
                {canCreateTemplate && (
                  <>
                    <br />
                    <a href={getPageUrl('clientAssessmentTemplates')}>
                      Get started
                    </a>
                  </>
                )}
              </span>
            }
          />
        </Col>
        <Col md={4}>
          <AssessmentScoreChartWidget
            title="Recent Assessment Scores"
            assessments={recentAssessmentScores}
            canLink={canLinkAssessment}
            useDefault={recentAssessmentScores.length === 0}
            defaultContent={
              <span>
                You don&apos;t have any Recent Assessments Scores.
                {canCreateTemplate && (
                  <>
                    <br />
                    <a href={getPageUrl('clientAssessmentTemplates')}>
                      Get started
                    </a>
                  </>
                )}
              </span>
            }
          />
        </Col>
        <Col md={4}>
          <Widget title="Issue Ranking Matrix">
            <IssueRankingMatrix data={issueMatrix} canLink={canListIssues} />
          </Widget>
        </Col>
      </Row>
      <Row $equalHeight>
        <Col md={5}>
          <Widget
            title="Recent Projects"
            moreText="View all projects"
            url={canListProjects && getPageUrl('clientProjects')}
            useDefault={recentProjects.length === 0}
            defaultContent={
              <span>
                You don&apos;t have any Projects yet.
                {canCreateProject && (
                  <>
                    <br />
                    <a href={getPageUrl('clientProjects')}>Get started</a>
                  </>
                )}
              </span>
            }
          >
            <Table
              data={recentProjects}
              columns={recentProjectsTableColumns}
              enableSorting={false}
              enablePagination={false}
            />
          </Widget>
        </Col>
        <Col md={7}>
          <Widget
            title="Upcoming Issues"
            moreText="View all issues"
            url={canListIssues && getPageUrl('clientIssues')}
            useDefault={upcomingIssues.length === 0}
            defaultContent={
              <span>
                You don&apos;t have any Open Issues.
                <br />
                <a href={getPageUrl('clientIssues')}>Get started</a>
              </span>
            }
          >
            <Table
              data={upcomingIssues}
              columns={upcomingIssuesTableColumns}
              enableSorting={false}
              enablePagination={false}
            />
          </Widget>
        </Col>
      </Row>
    </Wrapper>
  )
}

DashboardView.propTypes = {
  overview: PropTypes.object.isRequired,
  assessmentGrades: PropTypes.object.isRequired,
  issueMatrix: PropTypes.array.isRequired,
  activeAssessmentStatusCounts: PropTypes.object.isRequired,
  recentProjects: PropTypes.array.isRequired,
  recentAssessmentScores: PropTypes.array.isRequired,
  upcomingIssues: PropTypes.array.isRequired,
  onSendIssue: PropTypes.func.isRequired,
  onAssessmentStatusSelect: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default DashboardView
