import React from 'react'
import PropTypes from 'prop-types'
import { Times } from '@styled-icons/fa-solid/Times'
import styled from 'styled-components'
import Privva from 'Privva'
import Icon from 'components-v2/atoms/Icon'
import Button from 'components-v2/atoms/Button'
import Input from 'components-v2/atoms/Input'
import Alert from 'components-v2/molecules/Alert'
import ButtonGroup from 'components-v2/molecules/ButtonGroup'
import Loader from 'components-v2/molecules/Loader'
import ListGroup from 'components-v2/molecules/ListGroup'
import { Row, Col } from 'components-v2/organisms/Layout'
import OrganizationItem from './OrganizationItem'

const TimesButton = styled(Button)`
  height: 38px;
`

const SearchWrapper = styled(ButtonGroup)`
  display: flex;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

const View = ({
  organizations,
  query,
  page,
  total,
  perPage,
  noOrgs,
  loading,
  destOrg,
  priority,
  redirect,
  onQueryChange,
  onQueryClear,
  onPrevClick,
  onNextClick,
}) => {
  if (noOrgs) {
    return (
      <>
        <h4>Select Organization</h4>
        <Alert color="danger">
          <h5>
            This user account no longer has access to any Organizations in the
            system.
          </h5>
        </Alert>
        <p>
          If you feel this is an error, please contact&nbsp;
          <a href={Privva.Utils.brand.newSupportRequestUrl}>support</a>
        </p>
      </>
    )
  }
  return (
    <Row>
      <Col sm={2}></Col>
      <Col sm={8}>
        <h4>Select the organization you want to work on:</h4>
        <SearchWrapper>
          <Input
            type="text"
            value={query}
            placeholder="Search Organizations..."
            onChange={onQueryChange}
            maxLength={255}
          />
          <TimesButton disabled={!query} onClick={onQueryClear}>
            <Icon icon={Times} />
          </TimesButton>
        </SearchWrapper>
        <div className="top15">
          {loading && <Loader />}
          {!loading && (
            <div>
              <ListGroup>
                {destOrg && (
                  <OrganizationItem
                    org={destOrg}
                    priority={priority}
                    destOrg={destOrg}
                    redirect={redirect}
                  />
                )}
                {organizations
                  ?.filter((e) => e.id !== destOrg?.id)
                  .map((org) => (
                    <OrganizationItem
                      key={org.id}
                      org={org}
                      priority={priority}
                      destOrg={destOrg}
                      redirect={redirect}
                    />
                  ))}
              </ListGroup>
              <Footer>
                {page > 1 && (
                  <Button onClick={onPrevClick}>&larr; Previous Page</Button>
                )}
                <span />
                {total > page * perPage && (
                  <Button onClick={onNextClick}>Next Page &rarr;</Button>
                )}
              </Footer>
            </div>
          )}
        </div>
      </Col>
      <Col sm={2}></Col>
    </Row>
  )
}

View.propTypes = {
  organizations: PropTypes.array,
  query: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  redirect: PropTypes.string,
  destOrg: PropTypes.object,
  priority: PropTypes.string,
  noOrgs: PropTypes.bool,
  onQueryChange: PropTypes.func.isRequired,
  onQueryClear: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
}

export default View
