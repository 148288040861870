import React from 'react'
import Button from 'components-v2/atoms/Button'
import PageHeader from 'components-v2/organisms/PageHeader'
import { Row } from 'components-v2/organisms/Layout'
import { getPageUrl } from 'utils/url'
import { AssessmentStatusTypes, IssueStatusTypes } from 'constants/index'
import WelcomeCard from './WelcomeCard'
import StatsWidget from './StatsWidget'
import Widget from './Widget'
import { Wrapper, StyledCol as Col, ChartImg, TableImg } from './styles'

import statusChartImg from './images/statuschart.svg'
import scoresChartImg from './images/scoreschart.svg'
import issueMatrixImg from './images/issuematrix.svg'
import smallTableImg from './images/smalltable.svg'
import bigTableImg from './images/bigtable.svg'

const statsDetails1 = [
  {
    title: 'Assessments to Review',
    link: getPageUrl('clientAssessments', undefined, {
      status: [AssessmentStatusTypes.SUBMITTED, AssessmentStatusTypes.EXPIRED],
    }),
  },
  {
    title: 'Issues to Review',
    link: getPageUrl('clientIssues', undefined, {
      status: IssueStatusTypes.RESPONDED,
    }),
  },
]
const statsDetails2 = [
  {
    title: 'Invited Assessments',
    link: getPageUrl('clientAssessments', undefined, {
      status: AssessmentStatusTypes.INVITED,
    }),
  },
  {
    title: 'Open Issues',
    link: getPageUrl('clientIssues', undefined, {
      status: IssueStatusTypes.OPENED,
    }),
  },
]
const statsDetails3 = [
  {
    title: 'Average score',
    link: getPageUrl('clientAssessments', undefined, {
      status: AssessmentStatusTypes.REVIEWED,
      include_archived: ['vendors', 'projects'],
    }),
  },
  {
    title: 'Closed Issues',
    link: getPageUrl('clientIssues', undefined, {
      status: IssueStatusTypes.CLOSED,
    }),
  },
]

export default () => (
  <Wrapper>
    <PageHeader title="Dashboard">
      <Button href={getPageUrl('clientReports')}>
        <i className="fa fa-pie-chart" />
        Reports
      </Button>
    </PageHeader>
    <Row $equalHeight>
      <Col md={12}>
        <WelcomeCard
          newVendorUrl={getPageUrl('clientVendors')}
          newProjectUrl={getPageUrl('clientProjects')}
        />
      </Col>
    </Row>
    <Row $equalHeight>
      <Col md={4}>
        <StatsWidget details={statsDetails1} />
      </Col>
      <Col md={4}>
        <StatsWidget details={statsDetails2} />
      </Col>
      <Col md={4}>
        <StatsWidget details={statsDetails3} />
      </Col>
    </Row>
    <Row $equalHeight>
      <Col md={4}>
        <Widget title="Assessment Status">
          <ChartImg src={statusChartImg} />
        </Widget>
      </Col>
      <Col md={4}>
        <Widget title="Recent Assessment Scores">
          <ChartImg src={scoresChartImg} />
        </Widget>
      </Col>
      <Col md={4}>
        <Widget title="Issue Ranking Matrix">
          <ChartImg src={issueMatrixImg} />
        </Widget>
      </Col>
    </Row>
    <Row $equalHeight>
      <Col md={5}>
        <Widget
          title="Recent Projects"
          moreText="View all projects"
          moreUrl={getPageUrl('clientProjects')}
          ctaPosition="top"
        >
          <TableImg src={smallTableImg} />
        </Widget>
      </Col>
      <Col md={7}>
        <Widget
          title="Upcoming Issues"
          moreText="View all issues"
          moreUrl={getPageUrl('clientIssues')}
          ctaPosition="top"
        >
          <TableImg src={bigTableImg} />
        </Widget>
      </Col>
    </Row>
  </Wrapper>
)
