import styled from 'styled-components'
import Popover from 'components-v2/molecules/Popover'
import { media } from 'styles'
import NavItem from '../NavItem'

export const NotificationCount = styled.span`
  color: #fff;
  background-color: #909090;
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 8px;

  ${({ $hasUnopened }) => $hasUnopened && `background-color: #777777;`}
`

export const Container = styled.div`
  width: 330px;
  height: 490px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  flex: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 6px 10px;
  font-size: 14px;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPopover = styled(Popover)`
  ${media.mobile`
    display: none !important;
  `}
`

export const MobileNavItem = styled(NavItem)`
  ${media.nmobile`
    display: none !important;
  `}
`
