import React from 'react'
import isFunction from 'lodash/isFunction'

export default (test, ComponentOnPass, ComponentOnFail) => (props) => {
  if (isFunction(test) ? test(props) : test) {
    return <ComponentOnPass {...props} />
  }
  if (ComponentOnFail) {
    return <ComponentOnFail {...props} />
  }
  return null
}
