import React, { useState } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
// import Checkbox from 'components-v2/atoms/Checkbox'
import Select from 'components-v2/atoms/Select'
import { useUpdateEffect } from 'hooks'
import { colors } from 'styles'
import SingleImageUploader from './SingleImageUploader'
import ColorPicker from './ColorPicker'
import useOrganizationState from './useOrganizationState'
import useOrganizationColor from './useOrganizationColor'

const ThemeView = ({ organization }) => {
  const [internalLogo, setInternalLogo] = useState(organization.internal_logo)
  const [primaryColor, setPrimaryColor] = useOrganizationColor(
    'primary_color',
    organization,
  )
  const [bannerColor, setBannerColor] = useOrganizationColor(
    'banner_color',
    organization,
  )
  const [theme, setTheme] = useOrganizationState('theme', organization)
  // const [branding, setBranding] = useOrganizationState(
  //   'propagate_branding',
  //   organization,
  // )

  useUpdateEffect(() => {
    if (internalLogo) {
      $('#navbar-logo').attr('src', internalLogo.url)
    } else {
      const defaultSrc = $('#navbar-logo').data('src')
      $('#navbar-logo').attr('src', defaultSrc)
    }
  }, [internalLogo])

  useUpdateEffect(() => {
    document
      .getElementById('top-nav')
      .style.setProperty('--bg-color', bannerColor || colors.SMARSH_BLUE)
  }, [bannerColor])

  useUpdateEffect(() => {
    // update nav css
    const nav = document.getElementById('top-nav')
    if (nav) {
      nav.dataset.theme = theme
    }
  }, [theme])

  return (
    <table className="table">
      <tbody>
        <tr>
          <td>Site Logo</td>
          <td>
            <SingleImageUploader
              file={internalLogo}
              formParam="internal_logo"
              organizationId={organization.id}
              onChange={setInternalLogo}
            />
          </td>
        </tr>
        <tr>
          <td>Email Logo</td>
          {/* question circle image goes here */}
          <td>
            <SingleImageUploader
              defaultFile={organization.external_logo}
              formParam="external_logo"
              organizationId={organization.id}
            />
          </td>
        </tr>
        <tr>
          <td>Banner Color</td>
          <td>
            <ColorPicker value={bannerColor} onChange={setBannerColor} />
          </td>
        </tr>
        <tr>
          <td>Primary Color</td>
          <td>
            <ColorPicker
              value={primaryColor}
              maxLightness={0.75}
              onChange={setPrimaryColor}
            />
          </td>
        </tr>
        <tr>
          <td>Navigation Theme</td>
          <td>
            <Select
              value={theme || ''}
              onChange={(e) => setTheme(e.target.value)}
            >
              <option key="default" value="">
                Default
              </option>
              <option key="dark" value="dark">
                Dark
              </option>
            </Select>
          </td>
        </tr>
        {/* <tr>
          <td>Show site branding to all vendors</td>
          <td>
            <Checkbox
              checked={branding}
              onChange={(e) => setBranding(e.currentTarget.checked)}
            />
          </td>
        </tr> */}
      </tbody>
    </table>
  )
}

ThemeView.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default ThemeView
