import styled from 'styled-components'
import Clickable from 'components-v2/atoms/Clickable'
import { media } from 'styles'

export default styled(Clickable)`
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 12px;
  white-space: nowrap;
  --color: white;
  --color-hover: white;
  --color-open: white;
  --bg-hover: rgba(255, 255, 255, 0.3);
  --bg-open: rgba(0, 0, 0, 0.1);
  color: var(--color);

  &:focus,
  &:hover,
  &:active {
    color: var(--color-hover);
    background-color: var(--bg-hover);
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    `
    color: var(--color-open);
    background-color: var(--bg-open);

    &:focus,
    &:hover,
    &:active {
      color: var(--color-open);
      background-color: var(--bg-open);
    }
  `}

  [data-theme='dark'] & {
    --color: #777;
    --color-hover: #333;
    --color-open: #555;
    --bg-hover: rgba(0, 0, 0, 0.1);
    --bg-open: rgba(0, 0, 0, 0.1);
  }

  ${media.mobile`
    width: 100%;
    padding: 10px 15px;
    line-height: 22px;
    height: auto;
  `}
`
