import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Popover from 'components-v2/molecules/Popover'
import { getPageUrl } from 'utils/url'
import $ from 'jquery'
import NavItem from '../NavItem'
import {
  NotificationCount,
  Container,
  Header,
  Content,
  StyledPopover,
  MobileNavItem,
} from './styles'

function LazyNotificationsContent() {
  useEffect(() => {
    const path = encodeURIComponent(
      window.location.pathname + window.location.search,
    )
    $.ajax({
      url: `${getPageUrl('usersLazyNotifications')}?redirect=${path}`,
      type: 'GET',
      dataType: 'script',
    })
  }, [])

  return (
    <Container id="lazy_notifications_content">
      <Header>
        <span>Notifications</span>
      </Header>
      <Content>
        <i className="fa fa-spinner fa-spin fa-3x" />
      </Content>
    </Container>
  )
}

export default function NotificationPopover({
  userId,
  unopenedNotificationCount,
}) {
  return (
    <>
      <StyledPopover placement="bottom-end" offset={0}>
        <Popover.Button as={NavItem}>
          <NotificationCount $hasUnopened={!!unopenedNotificationCount}>
            {unopenedNotificationCount}
          </NotificationCount>
        </Popover.Button>
        <Popover.Overlay />
        <Popover.Panel>
          <LazyNotificationsContent />
        </Popover.Panel>
      </StyledPopover>
      <MobileNavItem href={getPageUrl('userNotifications', { id: userId })}>
        Notifications&nbsp;&nbsp;
        <NotificationCount $hasUnopened={!!unopenedNotificationCount}>
          {unopenedNotificationCount}
        </NotificationCount>
      </MobileNavItem>
    </>
  )
}

NotificationPopover.propTypes = {
  userId: PropTypes.string.isRequired,
  unopenedNotificationCount: PropTypes.number,
}
