import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import FormDialog from 'components-v2/organisms/FormDialog'
import {
  useClientAssessmentsFetcher,
  useClientAssessmentTemplatesFetcher,
} from 'apis'
import { getPageUrl } from 'utils/url'
import {
  AssessmentStatusTypes,
  ExportFormatTypes,
  LabelTypes,
} from 'constants/index'
import config from 'config'
import CompareVendorsFormField from '../fields/CompareVendorsFormField'
import SelectAssessmentTemplateFormField from '../fields/SelectAssessmentTemplateFormField'

const formConfig = {
  validationSchema: yup.object().shape({
    assessmentTemplate: yup.string().label('Assessment').required(),
    assessments: yup
      .array()
      .min(2, `Please choose at least 2 ${LabelTypes.VENDORS.toLowerCase()}`),
  }),
  initialValues: {
    assessmentTemplate: undefined,
    assessments: [],
  },
}

const ExportResponseComparisonModal = (props) => {
  const [templateId, setTemplateId] = React.useState()
  // Fetch assessment templates that have been completed by more than one vendor
  const { data: assessmentTemplates = [] } =
    useClientAssessmentTemplatesFetcher({
      params: {
        page: 1,
        per_page: config.collectionQueryTotalLimit.assessmentTemplates,
        has_assessments_status: [
          AssessmentStatusTypes.SUBMITTED,
          AssessmentStatusTypes.REVIEWED,
        ].join(','),
      },
    })
  // Fetch reviewed assessments using templateId
  const { data: assessments } = useClientAssessmentsFetcher(
    {
      params: {
        page: 1,
        per_page: config.collectionQueryTotalLimit.assessments,
        assessment_template_id: templateId,
        status: [
          AssessmentStatusTypes.SUBMITTED,
          AssessmentStatusTypes.REVIEWED,
        ].join(','),
      },
    },
    { enabled: !!templateId },
  )

  const handleAssessmentTemplateSelect = React.useCallback(
    (newTemplateId, setFieldValue) => {
      setTemplateId(newTemplateId)
      setFieldValue('assessments', [], false)
    },
    [],
  )

  const handleSubmit = React.useCallback(
    (formData) => {
      const params = {
        ids: formData.assessments.join(),
        assessment_template_id: formData.assessmentTemplate,
        format: ExportFormatTypes.XLSX,
      }
      window.location.assign(
        getPageUrl(
          'clientAssessmentsDetailedResponseComparisonExport',
          null,
          params,
        ),
      )
      props.onClose()
    },
    [props],
  )

  return (
    <FormDialog
      {...props}
      title="Export Detailed Response Comparison"
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <>
          <SelectAssessmentTemplateFormField
            assessmentTemplates={assessmentTemplates}
            onChange={(value) =>
              handleAssessmentTemplateSelect(value, setFieldValue)
            }
          />
          <CompareVendorsFormField assessments={assessments} />
        </>
      )}
    </FormDialog>
  )
}

ExportResponseComparisonModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ExportResponseComparisonModal
