import React from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import FormField from 'components-v2/molecules/FormField'
import { toLocalTimeString } from 'utils/date'
import config from 'config'
import { LabelTypes, SortOrderTypes } from 'constants/index'

const CompareVendorsFormField = ({ assessments, ...rest }) => {
  const options = React.useMemo(
    () =>
      orderBy(
        assessments,
        ['vendor.name', 'sent_at'],
        [SortOrderTypes.ASC, SortOrderTypes.DESC],
      ).map((assessment) => ({
        value: assessment.id,
        label: `${assessment.vendor.name} (Sent ${toLocalTimeString(
          assessment.sent_at,
          config.tableDateFormat,
        )})`,
      })),
    [assessments],
  )
  return (
    <FormField
      name="assessments"
      label={`Compare ${LabelTypes.VENDORS.toLowerCase()}`}
      type={FormField.types.MULTI_CHECKBOX}
      options={options}
      horizontal
      {...rest}
    />
  )
}

CompareVendorsFormField.propTypes = {
  assessments: PropTypes.array.isRequired,
}

export default CompareVendorsFormField
